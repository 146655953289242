import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Layout } from '@components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { StaticImage } from 'gatsby-plugin-image';
import Contact from '../../components/sections/contact';
// const StyledMainContainer = styled.main`
//   counter-reset: section;
// `;

const StyledProjectSection = styled.section`
  margin-top: 100px;
  padding: 30px;
  max-width: 1200px;

  .inner {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-gap: 50px;

    @media (max-width: 768px) {
      display: block;
    }
  }
  .section {
    margin-top: 20px;
  }
  .project-tech-list {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    z-index: 2;
    margin: 25px 0 10px;
    padding: 0;
    list-style: none;
    color: var(--lightest-slate);
    justify-content: flex-start;

    li {
      margin: 0 20px 5px 0;
      color: var(--light-slate);
      font-family: var(--font-mono);
      font-size: var(--fz-xs);
      white-space: nowrap;
      color: var(--green);
      font-family: var(--font-mono);
      font-weight: 400;
    }

    @media (max-width: 768px) {
      margin: 10px 0;

      li {
        margin: 0 0 5px 20px;
        margin: 0 10px 5px 0;

        @media (max-width: 768px) {
          margin: 0 10px 5px 0;
        }
      }
    }
  }
  .screenshots {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .screenshot {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    font-style: italic;
  }
  .green {
    color: var(--green);
  }
`;
// const StyledText = styled.div`
//   ul.skills-list {
//     display: grid;
//     grid-template-columns: repeat(2, minmax(140px, 200px));
//     grid-gap: 0 10px;
//     padding: 0;
//     margin: 20px 0 0 0;
//     overflow: hidden;
//     list-style: none;

//     li {
//       position: relative;
//       margin-bottom: 10px;
//       padding-left: 20px;
//       font-family: var(--font-mono);
//       font-size: var(--fz-xs);

//       &:before {
//         content: '▹';
//         position: absolute;
//         left: 0;
//         color: var(--green);
//         font-size: var(--fz-sm);
//         line-height: 12px;
//       }
//     }
//   }
// `;

const tech = [
  'Angular',
  '.NET Core',
  'PostgreSQL',
  'Linux',
  'NGINX',
  'CI/CD Pipelines',
  'Github Self Hosted Runners',
];

const DermDocProject = ({ location }) => {
  useEffect(() => {
    console.log(getImage('./dermdoc-4.png'));
  }, []);

  return (
    <Layout location={location}>
      <div className="container">
        <StyledProjectSection>
          <div className="App">
            <header className="App-header">
              <h1 className="big-heading">DermDoc</h1>
              {/* <h2>Patent-Centric Medical Software</h2> */}
            </header>
            <div>
              <div className="section overview">
                <h2>Overview</h2>
                <p>
                  DermDoc is a comprehensive platform that allows doctors to effortlessly track
                  their patients' individual visits, generating detailed reports and facilitating
                  in-depth analysis. This user-friendly application is tailored to improve doctors'
                  productivity by offering a range of features designed to simplify the patient
                  management process.
                </p>
              </div>
              <div className="section technologies">
                <h2>Technologies</h2>
                {tech.length && (
                  <ul className="project-tech-list">
                    {tech.map((tech, i) => (
                      <li key={i}>{tech}</li>
                    ))}
                  </ul>
                )}
              </div>
              <div className="section development">
                <h2>Development</h2>
                <p>
                  The frontend is built with Angular and Material UI, providing a user-friendly and
                  visually appealing interface. The backend utilizes .NET Core 8 and Entity
                  Framework for efficient data management. For the database i used PostgreSQL and
                  its deployed on Heroku.
                </p>
                <p>
                  Everything is deployed on a Debian 12 Linux machine using Nginx as a web server,
                  CI/CD piplelines used with Github Self Hosted Runners for tests and continuous
                  deployment.
                </p>
                <p>
                  Security on the Linux server is ensured by strict user management, SSH key
                  authentication, firewall configuration, continuous updates, and security tools
                  like Fail2ban.
                </p>
              </div>

              <div className="section features">
                <h2>Features</h2>
                <ul>
                  <li>
                    <span className="green">Patient-Centric Dashboard</span>: Access a centralized
                    dashboard displaying a snapshot of each patient's medical history, upcoming
                    appointments, and personalized care plans.
                  </li>
                  <li>
                    <span className="green">Visit Tracking</span>: Easily record and monitor
                    individual patient visits, including symptoms, treatments, and prescribed
                    medications. The intuitive interface ensures quick data entry, saving valuable
                    time during consultations.
                  </li>
                  <li>
                    <span className="green">Automated Reporting</span>: Generate comprehensive
                    reports with just a few clicks, providing a detailed overview of a patient's
                    medical journey. These reports include vital statistics, treatment plans, and
                    historical data, facilitating efficient communication with patients and
                    colleagues.
                  </li>
                  <li>
                    <span className="green">Conclusion and Decision Support</span>: Utilize the
                    platform's intelligent decision support system, which analyzes patient data to
                    suggest potential conclusions and treatment options. This assists doctors in
                    making informed decisions tailored to each patient's unique needs.
                  </li>
                  <li>
                    <span className="green">Enhanced Communication</span>: Facilitate seamless
                    communication between healthcare professionals within the platform. Share
                    insights, collaborate on complex cases, and ensure a coordinated approach to
                    patient care.
                  </li>
                </ul>
              </div>
              <div className="section benefits">
                <h2>Benefits</h2>
                <ul>
                  <li>
                    <span className="green">Time Efficiency: </span>By simplifying data entry and
                    automating report generation, doctors can focus more on patient interactions and
                    less on administrative tasks, leading to increased efficiency during
                    appointments.
                  </li>
                  <li>
                    <span className="green">Informed Decision-Making: </span>Access to detailed
                    analytics and intelligent insights empowers doctors to make well-informed
                    decisions, enhancing the overall quality of patient care.
                  </li>
                  <li>
                    <span className="green">Improved Patient Engagement: </span>By having a holistic
                    view of a patient's medical history readily available, doctors can engage
                    patients in more meaningful conversations about their health, fostering a
                    stronger doctor- patient relationship.
                  </li>
                </ul>
              </div>
              <div className="section screenshots">
                <h2>Screenshots</h2>
                <h3>
                  The following screenshots showcase the functionallities of the dermdoc application
                </h3>
                <div className="screenshot">
                  <StaticImage
                    src="../../../content/featured/derm-doc/dermdoc-login.png"
                    alt="A dinosaur"
                  />
                  <span>Derm Doc Login Screen</span>
                </div>
                <div className="screenshot">
                  <StaticImage
                    src="../../../content/featured/derm-doc/dermdoc-1.png"
                    alt="A dinosaur"
                  />
                  <span>Patient View Screen</span>
                </div>
                <div className="screenshot">
                  <StaticImage
                    src="../../../content/featured/derm-doc/dermdoc-2.png"
                    alt="A dinosaur"
                  />
                  <span>Add Patient Workflow</span>
                </div>
                <div className="screenshot">
                  <StaticImage
                    src="../../../content/featured/derm-doc/dermdoc-3.png"
                    alt="A dinosaur"
                  />
                  <span>Add Patient Workflow 2</span>
                </div>
                <div className="screenshot">
                  <StaticImage
                    src="../../../content/featured/derm-doc/dermdoc-4.png"
                    alt="A dinosaur"
                  />
                  <span>View Patient Screen</span>
                </div>
                <div className="screenshot">
                  <StaticImage
                    src="../../../content/featured/derm-doc/dermdoc-5.png"
                    alt="A dinosaur"
                  />
                  <span>Add Visit Screen</span>
                </div>
                <div className="screenshot">
                  <StaticImage
                    src="../../../content/featured/derm-doc/dermdoc-6.png"
                    alt="A dinosaur"
                  />
                  <span>View Visits For Selected Patient</span>
                </div>
                <div className="screenshot">
                  <StaticImage
                    src="../../../content/featured/derm-doc/dermdoc-7.png"
                    alt="A dinosaur"
                  />
                  <span>Generate Report For Visit</span>
                </div>
                <div className="screenshot">
                  <StaticImage
                    src="../../../content/featured/derm-doc/dermdoc-8.png"
                    alt="A dinosaur"
                  />
                  <span>Advanced Patient Filtering</span>
                </div>
                <div className="screenshot">
                  <StaticImage
                    src="../../../content/featured/derm-doc/dermdoc-9.png"
                    alt="A dinosaur"
                  />
                  <span>Advanced Patient Filtering Result</span>
                </div>
                <div className="screenshot">
                  <StaticImage
                    src="../../../content/featured/derm-doc/dermdoc-10.png"
                    alt="A dinosaur"
                  />
                  <span>Advanced Visit Filtering</span>
                </div>
              </div>
            </div>
          </div>
          <Contact />
        </StyledProjectSection>
      </div>
    </Layout>
  );
};

DermDocProject.propTypes = {
  location: PropTypes.object.isRequired,
};

export default DermDocProject;
